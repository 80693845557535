import * as React from "react";
import styled from "styled-components";
import { breakpoints, colors, fonts } from "../styles/variables";
import Wrapper from "./Wrapper";
import Saentispanorama from "../assets/saentispanorama.svg";
import Facebook from "../assets/facebook.svg";
import Instagram from "../assets/instagram.svg";

const StyledFooter = styled.footer`
  margin-top: 50px;
  padding-top: 30px;
  padding-bottom: calc(10vw + 100px);
  background-color: ${colors.brand};
  position: relative;

  a {
    color: white;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }

  @media print {
    background-color: white;

    a {
      color: ${colors.brand};
    }
  }
`;
const FooterColumns = styled.div`
  @media all and (min-width: ${breakpoints.sm}px) {
    display: flex;
    flex-wrap: wrap;
  }
`;
const FooterColumn = styled.div`
  min-width: 215px;
  z-index: 1;

  @media screen and (min-width: ${breakpoints.sm}px) {
    width: calc(100% / 2);
    padding-right: 12px;
    padding-left: 12px;

    &:nth-of-type(2n - 1) {
      padding-left: 0;
    }
    &:nth-of-type(2n) {
      padding-right: 0;
    }
  }
  @media screen and (min-width: ${breakpoints.md}px) {
    width: calc(100% / 3);

    &:nth-of-type(3n - 1) {
      padding-left: 0;
    }
    &:nth-of-type(3n) {
      padding-right: 0;
    }
  }
  @media screen and (min-width: ${breakpoints.md}px) {
    width: calc(100% / 5);
  }
`;

const FooterTitle = styled.h2`
  font-family: ${fonts.helvetica.bold};
  font-size: 16px;
  color: white;

  @media print {
    color: ${colors.brand};
  }
`;
const FooterText = styled.p`
  font-family: ${fonts.helvetica.regular};
  font-size: 14px;
  line-height: 1.6;
  color: white;

  @media print {
    color: ${colors.brand};
  }
`;

const Panorama = styled.img`
  display: block;
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 0;
  width: 100%;
  min-height: 120px;

  @media print {
    display: none;
  }
`;
const FacebookIcon = styled.img`
  width: 40px;
  padding: 4px;

  @media print {
    display: none;
  }
`;
const InstagramIcon = styled.img`
  width: 40px;
  padding: 4px;

  @media print {
    display: none;
  }
`;

const Footer: React.FC = () => {
  return (
    <StyledFooter>
      <Wrapper>
        <FooterColumns>
          <FooterColumn>
            <FooterTitle>Beraten und Buchen</FooterTitle>
            <FooterText>
              <a href="tel:+41713526974">Carreisen 071 352 69 74</a>
              <br />
              <a href="tel:+41713511818">Fahrdienst 071 351 18 18</a>
              <br />
              <a href="tel:+41713526974">Vermietung 071 352 69 74</a>
              <br />
              <a href="tel:+41713526974">Transport 071 352 69 74</a>
              <br />
            </FooterText>
          </FooterColumn>
          <FooterColumn>
            <FooterTitle>Ramsauer Carreisen GmbH</FooterTitle>
            <FooterText>
              <a href="mailto:info@ramsauer-carreisen.ch">
                info@ramsauer-carreisen.ch
              </a>
              <br />
              <br />

              <a
                href="https://www.instagram.com/ramsauer_carreisen/"
                target="_blank"
              >
                  <InstagramIcon src={Instagram} />
              </a>
              <a
                href="https://www.facebook.com/Ramsauercarreisen/"
                target="_blank"
              >
                <FacebookIcon src={Facebook} />
              </a>

              <br />
              <br />
            </FooterText>
          </FooterColumn>
          <FooterColumn>
            <FooterTitle>Herisau</FooterTitle>
            <FooterText>
              Schwellbrunnerstrasse 31
              <br />
              9100 Herisau
              <br />
              <a href="tel:+41713526974">Telefon 071 352 69 74</a>
              <br />
            </FooterText>
          </FooterColumn>
          <FooterColumn>
            <FooterTitle>Gossau</FooterTitle>
            <FooterText>
              Moosburgstrasse 12
              <br />
              9200 Gossau
              <br />
              <a href="tel:+41713853833">Telefon 071 385 38 33</a>
              <br />
            </FooterText>
          </FooterColumn>
          <FooterColumn>
            <FooterTitle>Ramsauer Fahrdienst</FooterTitle>
            <FooterText>
              <a href="tel:+41713511818">Telefon 071 351 18 18</a>
              <br />
              <a href="mailto:info@ramsauer-fahrdienst.ch">
                info@ramsauer-fahrdienst.ch
              </a>
              <br />
              <a href="https://www.ramsauer-fahrdienst.ch">
                www.ramsauer-fahrdienst.ch
              </a>
              <br />
            </FooterText>
          </FooterColumn>
        </FooterColumns>
      </Wrapper>
      <Panorama src={Saentispanorama}></Panorama>
    </StyledFooter>
  );
};

export default Footer;
